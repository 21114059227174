import React, { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap'

export default function CaseStudyHero({ title, img, imgone, imgtwo,
    imgthree, subheadingone,
    subheadingtwo, subheadingthree, titleone, titletwo, titlethree,
    googlStoreApi,
    iosStroeApi,
    websiteurl,
    appbuttonnone = true
}) {

    const [loading, setLoading] = useState(true);
    setTimeout(() => {
        setLoading(false)
    }, 1000)
    return (
        <>
            <section className='case-study-hero'>
                <Container>
                    {
                        loading ? '' :
                            <>
                                <Row className='align-items-center'>
                                    <Col xl={6} md={12} className=" order-xl-1 order-2">
                                        <div className="hero-content">
                                            <h1>{title}</h1>
                                            {
                                                !websiteurl &&
                                                <>
                                                    <div className='case-box'>
                                                        {/* <div className="case-box-one">
                                                            <img src={imgone} alt={title} />
                                                            <h2>{subheadingone ? subheadingone : '15k'}</h2>
                                                            <p>{titleone}</p>
                                                        </div>
                                                        <div className="case-box-one">
                                                            <img src={imgtwo} alt={title} />
                                                            <h2>{subheadingtwo ? subheadingtwo : '40K'}</h2>
                                                            <p> {titletwo} </p>
                                                        </div>
                                                        <div className="case-box-one">
                                                            <img src={imgthree} alt={title} />
                                                            <h2>{subheadingthree ? subheadingthree : '15k'}</h2>
                                                            <p> {titlethree} </p>
                                                        </div> */}
                                                        {
                                                            subheadingone &&
                                                            <div className="case-box-one">
                                                                <img src={imgone} alt={title} />
                                                                <h2>{subheadingone ? subheadingone : '15k'}</h2>
                                                                <p>{titleone}</p>
                                                            </div>
                                                        }
                                                        {
                                                            subheadingtwo &&
                                                            <div className="case-box-one">
                                                                <img src={imgtwo} alt={title} />
                                                                <h2>{subheadingtwo ? subheadingtwo : '40K'}</h2>
                                                                <p> {titletwo} </p>
                                                            </div>
                                                        }
                                                        {
                                                            subheadingthree &&
                                                            <div className="case-box-one">
                                                                <img src={imgthree} alt={title} />
                                                                <h2>{subheadingthree ? subheadingthree : '15k'}</h2>
                                                                <p> {titlethree} </p>
                                                            </div>
                                                        }

                                                    </div>
                                                </>
                                            }

                                            {
                                                appbuttonnone ? <div className="display-hero">

                                                    <div className="d-flex btn-mania mt-sm-4 mt-2 justify-content-center  justify-content-xl-start ">
                                                        {
                                                            websiteurl && <><a href={websiteurl} target="_blank">
                                                                {/* <Button className='request-header-mobile'>Visit Website</Button> */}

                                                                <img src="../assets/img/casestudy/accubow_download/visit-website.svg" alt="visitwebsite" className='img-fluid' />
                                                            </a></>
                                                        }
                                                        {
                                                            googlStoreApi && <><a href={googlStoreApi} target="_blank">
                                                                <img src="../assets/img/casestudy/accubow_download/googleplay.svg" alt="googleplay" className='img-fluid' />
                                                            </a></>
                                                        }

                                                        {
                                                            iosStroeApi && <><a href={iosStroeApi} target="_blank">
                                                                <img src="../assets/img/casestudy/accubow_download/appstore.svg" alt="appstore" className='img-fluid' />
                                                            </a></>
                                                        }

                                                    </div>
                                                </div>
                                                    : ''

                                            }


                                        </div>
                                    </Col>
                                    <Col xl={6} md={12} className="order-xl-2  order-1">
                                        <div className="hero-img ">
                                            <img src={img} className="img-fluid model-img-accubow" alt="case-study-img" />
                                        </div>

                                    </Col>
                                </Row>
                            </>
                    }
                </Container>
            </section>
        </>
    )
}
